import React from 'react'
import './GettingStartedStyles.css'
import GearLogo from '../assets/gear-solid.svg'
import WalletLogo from '../assets/wallet-solid.svg'
import QuestionLogo from '../assets/clipboard-question-solid.svg'

const GettingStarted = () => {
    return (
        <div className='pricing' id='getting-started'>
            <div className="title"><h1>Getting Started</h1></div>
            <div className='card-container'>
                <div className='card'>
                    <h2><img src={GearLogo} style={{height:'32px', width:'32px'}} />&nbsp;API & SDK<br/> </h2>
                    <p>Our comprehensive documentations for your development and testing</p>
                    <a href='https://developer.fnsbsa.com' target='_blank' className='btn'>View Docs</a>
                </div>
                <div className='card'>
                    <h2><img src={WalletLogo} style={{height:'32px', width:'32px'}} />&nbsp;FNSPay</h2>
                    <p> Demonstration on how BSA works as a banking authentication solutions</p>
                    <a href='https://fnspay.fnsbsa.com' target='_blank' className='btn'>See Demo</a>
                </div>
                <div className='card'>
                    <h2><img src={QuestionLogo} style={{height:'32px', width:'32px'}} />&nbsp;FAQ </h2>
                    <p>Understand more with our Frequently Asked Questions </p>
                    <a href='#faq' className='btn'>Read FAQ</a>
                </div>
            </div>
        </div>
    )
}

export default GettingStarted
