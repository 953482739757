import React, { useState } from 'react'
import './AboutBSAStyles.css'
import './Accordion.css';
import sandFaq from './faq-sandbox.json'
import appFaq from './faq-bsa-app.json'
import apkFaq from './faq-bsa-apk.json'
import demoMobileFaq from './faq-demo-mobile.json'
import demoWebFaq from './faq-demo-web.json'
import androidSDKFaq from './faq-android-sdk.json'
import iosSDKFaq from './faq-ios-sdk.json'
import webAPIfaq from './faq-web-api.json'

const FAQ = () => {

    const [activeIndex, setActiveIndex] = useState(-1);
    const [subActiveIndex, setSubActiveIndex] = useState(-1);
    const [subSubActiveIndex, setSubSubActiveIndex] = useState(-1);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    };

    const subHandleClick = (subIndex) => {
        setSubActiveIndex(subIndex === subActiveIndex ? -1 : subIndex);
    };

    const subSubHandleClick = (subSubIndex) => {
        setSubSubActiveIndex(subSubIndex === subSubActiveIndex ? -1 : subSubIndex);
    };

    const formating = (itemContent) => {
        var formatted = itemContent.split('\n');
        console.log(formatted);
    }

    const items = [{
                    title: "1. Sandbox",
                    content: sandFaq
                },
                {
                    title: "2. Demo App",
                    content: [{
                        title: "2.1. Demo Mobile",
                        content: demoMobileFaq
                    },
                    {
                        title: "2.2. Demo Web",
                        content: demoWebFaq
                    }]
                },
                {
                    title: "3. API & SDK",
                    content: [{
                        title: "3.1. Android SDK",
                        content: androidSDKFaq
                    },
                    {
                        title: "3.2. iOS SDK",
                        content: iosSDKFaq
                    },
                    {
                        title: "3.3. Web API",
                        content: webAPIfaq
                    }]
                },
                {
                    title: "4. BSA APK",
                    content: apkFaq
                },
                {
                    title: "5. BSA App",
                    content: appFaq
                }];

    return (
        <div className='pricing' id='faq'>
            <div className="title"><h1>Frequently Asked Questions</h1></div>
                <div className='accordion-container'>
                    {items.map((item, index) => (
                        <div className='accordion' key={item.title}>
                            <button onClick={() =>handleClick(index)} style={{fontWeight:'bold'}}>{item.title}</button>
                            {index === activeIndex && <p style={{marginTop:'unset'}}>{typeof item.content !== 'object' ? item.content : item.content.map((subItem, subIndex) => (
                                <div className='accordion sub' key={subItem.title}>
                                    <button onClick={() =>subHandleClick(subIndex)}>{subItem.title}</button>
                                    {subIndex === subActiveIndex && <p style={{marginLeft: '10px', paddingLeft: '10px', fontWeight: '500'}}>{
                                    typeof subItem.content !== 'object' ? subItem.content : 
                                    subItem.content.map((subSubItem, subSubIndex) => (
                                        <div className='accordion' key={subSubItem.title}>
                                            <button onClick={() =>subSubHandleClick(subSubIndex)}>{subSubItem.title}</button>
                                            {subSubIndex === subSubActiveIndex && <p style={{marginLeft: '10px', paddingLeft: '10px'}}>{subSubItem.content}</p>}
                                        </div>
                                    ))}</p>}
                                </div>
                            ))}</p>}
                        </div>
                    ))}
                </div>
        </div>
    )
}

export default FAQ
