import React from 'react';
import Support from '../components/Support'
import Navbar from '../components/Navbar'
import '../components/VideoStyles.css'
import GettingStarted from '../components/GettingStarted'
import FAQ from '../components/FAQ';
import { useRef } from 'react';
import '../components/AboutBSAStyles.css'
import AboutBSA from '../components/AboutBSA'
import GoToTopButton from '../components/GoToTopButton';
import '../components/GoToTopButtonStyles.css';
import Footer from '../components/Footer';

const Home = () => {
    const sectionRef = useRef(null);

    const scrollToSection = () => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      };
    
    const getStartedRef = useRef(null);

    return (
        <div>
            <Navbar />
            {/* <div className='hero' >
                <div className='content' >
                    <div >
                    <h1>BSA Sandbox</h1>
                    <p style={{color:'rgba(60, 60, 67)'}}>Technical Portal</p>
                    <div>
                    <button onClick={scrollToSection} className='btn'>Learn More</button>
                    </div>
                    </div>
                </div>
            </div> */}
            <div>
                {/* <section ref={sectionRef} ></section> */}
                <AboutBSA/>
            </div>
            <section ref={getStartedRef} ></section>
            <GettingStarted/>
            <section ></section>
            <FAQ/>
            <Support />
            <Footer />
            <GoToTopButton />
        </div>
    )
}

export default Home;
