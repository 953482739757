import React from 'react'
import './AboutBSAStyles.css'   
import { useRef } from 'react'
import PhoneBSA from '../assets/1_Main Page.png'
import PhoneLogo from '../assets/1_logopage.png'
import AppleLogo from '../assets/App Store Button White 2.png'
import AndroidLogo from '../assets/Google Play Button White.png'
import BSALogo from '../assets/BSALogo.png'
import {useState,useEffect} from 'react'
import './CustomStyles.css'

const Training = () => {

        const [fadeIn, setFadeIn] = useState(false);
      
        // Add a delay to set the 'fadeIn' state to true after the component mounts
        useEffect(() => {
          const fadeInTimeout = setTimeout(() => {
            setFadeIn(true);
          }, 100);
      
          // Clear the timeout when the component unmounts
          return () => clearTimeout(fadeInTimeout);
        }, []);

    const getStartedRef = useRef(null);

    const scrollToGetStarted = () => {
        getStartedRef.current.scrollIntoView({ behavior: 'smooth' });
      };

    return (
        <div >
        <div className='training'  >
            
            <div className='left'>
                <h1 style={{ textAlign:'left', paddingLeft:'20px'}}>BSA Sandbox</h1>
                <p style={{marginTop:'unset', fontSize:'56px', fontWeight:'bolder'}}>Technical Portal</p>
                <p style={{height:'auto', color:'#3C3C43BF', width: '585px'}}>BSA Sandbox is a valuable tool that empowers technology professionals to innovate, troubleshoot, and optimize their systems and applications in a risk-free environment, ensuring the stability and security of the production environment. </p>
                <div style={{paddingLeft:'20px'}}>
                {/* <a href='https://web.fnsbsa.com/' target='_blank' style={{ backgroundColor:'rgb(0, 0, 0)',
                color:'rgb(255, 255, 255)',
                borderRadius:'5px',
                position:'relative',
                left: '2px',
                height: '40px',
                width: '130px',
                display: 'inline-flex',
                marginRight: '5px', fontSize: '14px'}}><img src={BSALogo} style={{ width:'auto' }} /><span style={{ padding:'1px',
                    display:'inline-flex',
                    position:'inherit',
                    alignItems:'center',
                    fontWeight:500
                }}> BSA Web</span></a> */}
                <a href='https://web.fnsbsa.com/' target='_blank' style={{ backgroundColor:'#4565d8',
                color:'rgb(255, 255, 255)',
                borderRadius:'10px',
                position:'relative',
                left: '2px',
                height: '45px',
                width: '130px',
                display: 'inline-flex',
                marginRight: '5px', fontSize: '14px'}}><img src={BSALogo} style={{ width:'auto' }} /><span style={{ padding:'1px',
                    display:'inline-flex',
                    position:'inherit',
                    alignItems:'center',
                    fontWeight:500
                }}> BSA Web</span></a>
                <a href='https://apps.apple.com/us/app/bsa-itu-prod/id6451237527' target='_blank' style={{ backgroundColor:'#4565d8',
                color:'rgb(255, 255, 255)',
                borderRadius:'10px',
                position:'relative',
                left: '2px',
                height: '45px',
                width: '130px',
                display: 'inline-flex',
                marginRight: '5px', fontSize: '14px'}}><img src={AppleLogo} style={{ width:'auto' }} /></a>
                <a href='https://resource.fnsbsa.com/resource/BSA-Sandbox.apk' target='_blank' style={{ backgroundColor:'#4565d8',
                color:'rgb(255, 255, 255)',
                borderRadius:'10px',
                position:'relative',
                left: '2px',
                height: '45px',
                width: '130px',
                display: 'inline-flex',
                marginRight: '5px', fontSize: '14px'}}><img src={AndroidLogo} style={{ width:'auto' }} /></a>
                {/* <a href='https://apps.apple.com/us/app/bsa-itu-prod/id6451237527' target='_blank'><img src={AppleLogo} /></a> */}
                {/* <a href='https://resource.fnsbsa.com/resource/BSA-Sandbox.apk' target='_blank'><img src={AndroidLogo} /></a> */}
                </div>
            </div>
            <div className='right'>
                <div className='img-container'>
                    <div className='image-stack top'>
                        <img src={PhoneBSA} className='img' alt='' />
                    </div>
                    <div className='image-stack bottom'>
                        <img src={PhoneLogo} className='img' alt='' />
                    </div>
                </div>
            </div>
        </div>
        <section ref={getStartedRef} ></section>
        </div>
        
    )
}

export default Training
