import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {FaBars, FaTimes} from 'react-icons/fa'
import './NavbarStyles.css'
import BSALogo from '../assets/bsa-shield_0.png'

const Navbar = () => {
    const[click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const [color, setColor] = useState(false)
        const changeColor =() => {
            if(window.scrollY >= 100) {
                setColor(true)
            } else {
                setColor(false)
            }
        }

        window.addEventListener('scroll', changeColor)

        const style ={
            width: '66px',
            position:'grid',
        };



    return (
        <div>
        <div className={color ? 'header header-bg' : 'header'}>
            <ul className='nav-menu active logo' style={{marginLeft:"145px"}}  >
                <li><Link to='/'><img src={BSALogo} style={style} className='img' alt="logo"></img>&nbsp;<span className='title' style={{padding:'unset', fontSize:'20px', fontWeight:'850'}}>BSA</span></Link></li>
            </ul>
            <ul className={click ? 'nav-menu active menu' : 'nav-menu menu'} style={{alignItems:"right",marginRight:"145px"}}>
               <li>
                   <a href='#getting-started'>Getting Started</a>
               </li>
               <li>
                    <a href="https://developer.fnsbsa.com" target='_blank'>Documentation</a>
               </li>
               <li>
                   <a href='#faq'>FAQ</a>
               </li>
               <li>
                   <a href='#support'>Support</a>
               </li>
           </ul>
           <div className='hamburger' onClick={handleClick}>
            {click ? (<FaTimes size={20} style={{color: '#000000'}} />) : (<FaBars size={20} />)}
           </div>
        </div>
        </div>
    )
}

export default Navbar
