import React from 'react'
import './GettingStartedStyles.css'

const Support = () => {
    return (
        <div className='support' id='support' style={{borderBottom: '1px solid #e2e2e3', padding: '64px 64px 128px 64px'}}>
            <div className='card-container'>
                <div className='card' style={{backgroundColor: 'unset', border: 'unset'}}>
                    <div className='title'><h1>Help Desk</h1></div>
                    <p style={{fontSize:'14px', color: 'rgba(60, 60, 67, .75)', height: 'auto'}}>For additional requests and assistance, please click the button below to send an email.</p>
                    <br/>
                    <a href='mailto:bds.helpdesk@fnsvalue.co.kr' target='_blank' className='btn' style={{width: 'auto'}}>Email Us</a>
                </div>
            </div>
        </div>
    )
}

export default Support
