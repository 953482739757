import React from 'react'
import './FooterStyles.css'
import { FaMailBulk, FaPhone, FaSearchLocation } from 'react-icons/fa'

const Footer = () => {
    return (
        <div className='footer' id='footer'>
            <div className='footer-container'>
                <div>
                    Copyright © 2023-2024 FNSVALUE. All rights reserved
                </div>
            </div>
        </div>
    )
}

export default Footer
